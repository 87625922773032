<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <h4 class="font-weight-bold mb-4">Your contact details</h4>
            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="">Title</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="client.title"
                  placeholder="Title"
                />
              </div>

              <div class="col-md-4 mb-3">
                <label for="">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="First name"
                  v-model="client.first_name"
                />
              </div>
              <div class="col-md-4 mb-3">
                <label for="">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last name"
                  v-model="client.last_name"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 mb-3">
                <label for="">Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email address"
                  disabled
                  v-model="client.email"
                />
              </div>
              <div class="col-md-4 mb-3">
                <label for="">Phone</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="client.phone"
                />
              </div>

              <div class="col-md-4 mb-3">
                <label for="">Mobile</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="client.mobile"
                />
              </div>
            </div>

            <!--  -->

            <div class="row mt-3">
              <div class="col-auto ms-auto">
                <button @click="updateClientInfo" class="btn btn-success">
                  <i class="far fa-check me-2"></i>Update Info
                </button>
              </div>
            </div>

            <h4 class="font-weight-bold mb-4">Our contact details</h4>
            <div class="alert alert-success">
              <p>
                You may contact our team via telephone on
                <a href="tel:+441823400070">+44 1823 400 070</a>
              </p>
              <p class="mb-3">
                Amchara Retreats<br />
                Bindon Country House<br />
                Langford Budville<br />
                Somerset<br />
                TA21 0RU<br />
                UK
              </p>

              <a
                href="https://www.google.co.uk/maps/place/Amchara+Health+Retreats/@51.0092881,-3.2810421,17z/data=!4m8!1m2!2m1!1sBindon+Country+House,+Langford+Budville,+Somerset,+TA21+0RU!3m4!1s0x486deda8343136fd:0x7cf19b4286a3bfec!8m2!3d51.0092845!4d-3.2788481"
                class="btn btn-outline-success btn-sm"
                ><i class="far fa-map me-2"></i>Open in Google Maps</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      client: this.$store.user,
    };
  },
  methods: {
    updateClientInfo() {
      if (!this.client.first_name || !this.client.last_name || !this.client.email) {
        this.$EventBus.$emit("alert", {
          color: "danger", message: "Please fill out the name and email fields."
        });
        return;
      }

      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/c/clients/" + this.client.id,
          this.client
        )
        .then(({ data }) => {
          this.$store.user = data.client;
          this.$EventBus.$emit("alert", data);
        });
    },
  }
};
</script>

<style></style>
